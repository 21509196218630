import Intro from './HomePage/Intro';
import About from './HomePage/About';
import Services from './HomePage/Services';
import Partners from './HomePage/Partners';
import Works from './HomePage/Works';
import Experience from './HomePage/Experience';
import Team from './HomePage/Team';
import Hire from './HomePage/Hire';

export default function HomePage() {
	return (
		<div className="page__home">
			<Intro/>
			<About/>
			<Services/>
			<Partners/>
			<Works/>
			<Experience/>
			<Team/>
			<Hire/>
		</div>
	);
}