import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';

import {createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory} from 'history';


import createRootReducer from './reducers';
import rootSaga from './sagas';

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
    history: createBrowserHistory(),
    //other options if needed
});

export default function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        createRootReducer(routerReducer),
        preloadedState,
        composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);

    const history = createReduxHistory(store);

    return {
        store,
        history
    };
};