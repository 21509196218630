import React from 'react';
import ReactDOM from 'react-dom/client';

import './stylesheets/style.scss';
import reportWebVitals from './reportWebVitals';

import {Provider} from 'react-redux';
import {HistoryRouter as Router} from "redux-first-history/rr6";

import configureStore from './react/configureStore';
import AppRoutes from './react/routes';

const persistedState = {};
const {store, history} = configureStore(persistedState);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <AppRoutes/>
            </Router>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
