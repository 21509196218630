import HeaderNavbar from './../HeaderNavbar';

export default function Intro() {
	return (
		<section className="home__intro" id="intro">
			<HeaderNavbar/>

			<div className="container-h">
				<div className="home__intro__heading">
					<div className="home__intro__text">
						<h1>Frontend development company</h1>
						<p className="text--lead">We help companies make interaction with their products and services as simple as possible</p>
					</div>
				</div>
			</div>
		</section>
	);
}