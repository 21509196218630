export default function About() {
	return (
		<section className="home__about" id="about">
			<div className="container-h container-v">
				<h2>What We Do</h2>

				<p>
					We are a technical partner of design agencies
				</p>

				<p>
					We want companies to have an opportunity to only deal with the layouts and design ideas, and we take care of the technical part
				</p>
			</div>
		</section>
	);
}