import {HashLink} from 'react-router-hash-link';
import React from "react";
import PropTypes from "prop-types";

const navbarNavItems = [
    {
        title: 'About',
        url: '#about'
    },
    {
        title: 'Services',
        url: '#services'
    },
    {
        title: 'Works',
        url: '#works'
    },
];

export default class HeaderNavbar extends React.Component {
    static propTypes = {
        theme: PropTypes.string,
    };

    static defaultProps = {
        theme: 'transparent',
    };

    render() {
        const {theme} = this.props;

        return (
            <div className={`navbar__main navbar__main--${theme}`}>
                <div className="container-h--large">
                    <div className="navbar">
                        <div className="navbar-header">
                            <HashLink smooth to="/#intro">Valpio</HashLink>
                        </div>

                        <nav className="navbar-nav">
                            {navbarNavItems.map((item, i) => (
                                <HashLink smooth key={i} to={`/${item.url}`}>{item.title}</HashLink>
                            ))}
                        </nav>

                        <div className="navbar__right">
                            <HashLink smooth to="/#hire-us" className="navbar__main__cta">Let’s talk about your project</HashLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}