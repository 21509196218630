import React, {useEffect} from 'react';
import {deviceType} from 'detect-it';
import {Outlet}  from 'react-router-dom';

export default function Layout() {
	useEffect(() => {
		if (deviceType === 'touchOnly') {
			document.querySelector('html').classList.remove('has-hover');
			document.querySelector('html').classList.add('no-hover');
		} else {
			document.querySelector('html').classList.add('has-hover');
			document.querySelector('html').classList.remove('no-hover');
		}
	});

	return (
		<div className="layout">
			<Outlet/>
		</div>
	);
}