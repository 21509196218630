import {Link} from 'react-router-dom';

import FormControl from './../form/FormControl';

export default function HireForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="error-message js-form-error-message is-hidden"/>

            <div className="js-form-content">
                <div className="form-group">
                    <FormControl
                        name="name"
                        value={props.data.name}
                        type="text"
                        isRequired
                        placeholder="Your name"
                        label="Your name"
                        onChange={props.onInputChange}
                    />
                </div>

                <div className="form-group">
                    <FormControl
                        name="email"
                        value={props.data.email}
                        type="email"
                        isRequired
                        placeholder="Email"
                        label="Email"
                        onChange={props.onInputChange}
                    />
                </div>

                <div className="form-group">
                    <FormControl
                        name="question"
                        value={props.data.question}
                        type="text"
                        placeholder="How can we help. Share your thoughts"
                        label={
                            <>
                                How can we help. Share your thoughts <span className="text--color-small">(Optional)</span>
                            </>
                        }
                        onChange={props.onInputChange}
                    />
                </div>


                <div className="form-message">
                    <p>
                        By clicking the Send Request button you agree to our <Link to={'#'}>Privacy Policy</Link> term
                    </p>
                </div>

                <div className="form-footer">
                    <button type="submit" className="btn btn--outline">
                        <span className="btn__content">
                            SEND REQUEST
                        </span>
                    </button>
                </div>
            </div>

            <div className="is-hidden js-form-success contact-form__success">
                <h2 className="h4 leading-trim">Спасибо!</h2>
                <p className="mt-s-2 mt-s-3:lg pb-s-2 leading-trim">
                    Мы перезвоним в
                    течение<br/> двух часов.
                </p>
            </div>
        </form>
    );
}