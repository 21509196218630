export default function Experience() {
	return (
		<section className="home__experience">
			<div className="container-h container-v">
				<p className="text--center">
					Years of practice, thousands of hours spent on dozens of successful projects by a talented software development team: these numbers can work in favour of your project now
				</p>

				<div className="home__experience__numbers">
					<div>
						<div className="home__experience__number">
							10+
						</div>
						<p>Years of experience</p>
					</div>

					<div>
						<div className="home__experience__number">
							130+
						</div>
						<p>Projects done</p>
					</div>
				</div>
			</div>
		</section>
	);
}