import oleksandr from './../../../assets/images/team/oleksandr.png';
import serhii from './../../../assets/images/team/serhii.png';
import ivanov from './../../../assets/images/team/ivanov-serhii.png';

const team = [
	{
		img: oleksandr,
		name: 'Hryshchenko Oleksandr',
		position: 'Frontend team lead',
		exp: '10+ years of experience in HTML/CSS & JS (React)',
		skill: 'Mobile performance, Pixel Perfect. Team Lead'
	},
	{
		img: serhii,
		name: 'Hryshchenko Serhii',
		position: 'Co-founder, chief technology officer',
		exp: '14+ years of experience in HTML/CSS & JS, PHP(Symfony).',
		skill: 'Senior Information Technology leader with extensive experience in managing successful web development and operational teams.'
	},
	{
		img: ivanov,
		name: 'Ivanov Serhii',
		position: 'Co-founder, chief operating officer',
		exp: '9+ years of Product Development experience.',
		skill: 'Specialize in hiring contractors and employees, organizing day-to-day work production, team and department leadership'
	}
];

export default function Team() {
	return (
		<section className="home__team">
			<div className="container-h container-v">
				<h2 className="text--center">Team</h2>

				<div className="team__list">
					{team.map((member, i) => (
						<div key={i} className="member">
							<div className="member__img">
								<img src={member.img} alt={member.name}/>
							</div>

							<div className="member__name">{member.name}</div>
							<div className="member__position">{member.position}</div>
							<div className="member__exp">{member.exp}</div>
							<div className="member__skill">{member.skill}</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}