import videinfraLogo from './../../../assets/images/partners/videinfra-logo.png';
import videinfraImg from './../../../assets/images/partners/videinfra.jpg';
import intechnicLogo from './../../../assets/images/partners/intechnic-logo.png';
import intechnicImg from './../../../assets/images/partners/intechnic.jpg';
import apusLogo from './../../../assets/images/partners/apus-logo.png';
import apusImg from './../../../assets/images/partners/apus.jpg';

const partners = [
	{
		title: 'Vide Infra',
		shortDescr: 'UX, Web & Product <br/>Design Agency',
		logo: videinfraLogo,
		img: videinfraImg,
		description: 'Vide Infra is an award‑winning design and cutting‑edge technological solutions. Vide Infra is a reliable, responsible and competent partner for developing effective digital products and marketing solutions'
	},
	{
		title: 'Intechnic',
		shortDescr: 'UX, Web & Product <br/>Design Agency',
		logo: intechnicLogo,
		img: intechnicImg,
		description: 'Intechnic is a world-leading User Experience agency specializing in UX Research & Strategy, UX Design, and Web Design'
	},
	{
		title: 'Apus Agency',
		shortDescr: 'UX, Web & Product <br/>Design Agency',
		logo: apusLogo,
		img: apusImg,
		description: 'Apus Agency is a team of experts specializing in graphic design, communications and web development'
	}
];

export default function Partners() {
	return (
		<section className="home__partners">
			<div className="container-h container-v">
				<h2 className="text--center">Partners</h2>

				{partners.map((partner, i) => (
					<div className="partner" key={i}>
						<div className="partner__intro">
							<img src={partner.logo} alt={partner.title}/>

							<div>
								<div className="partner__title">{partner.title}</div>
								<div className="partner__short-descr" dangerouslySetInnerHTML={{__html: partner.shortDescr}}/>
							</div>
						</div>

						<div className="partner__img">
							<img src={partner.img} alt={partner.title}/>
						</div>

						<div className="partner__descr">
							{partner.description}
						</div>
					</div>
				))}
			</div>
		</section>
	);
}