import {Route, Routes}  from 'react-router-dom';

import Layout from './components/Layout/Layout';
import NotFound from './components/NotFound/NotFound';
import HomePage from './components/HomePage';


export default function AppRoutes() {
	return (
		<Routes>
			<Route path="*" element={<Layout/>}>
				<Route index element={<HomePage/>} />

				<Route path="*" element={<NotFound/>} />
			</Route>
		</Routes>
	)
}