const frontendTags = ['Responsive & cross-browser', 'SEO & structured data', 'Robust frameworks', 'Single-page applications', 'Multi-page websites', 'Animations', 'Websites optimizations for mobile', 'Fast & optimized', 'ARIA standards'];

const backendTags = ['LAMP stack', 'AWS, Google Cloud', 'Test-driven development', 'Symfony framework', 'Product framework design', 'Established coding style'];

export default function Services() {
	return (
		<section className="home__services" id="services">
			<div className="container-h container-v">
				<h2 className="text--center">Services</h2>

				<div className="home__services__item">
					<div className="home__services__item__description">
						<h3>Frontend development</h3>

						<p>
							Fast, technically unimpeachable, with advanced UI animations — how your product looks and behaves plays a critical role in creating lasting impressions on your customers.
						</p>
					</div>

					<div className="home__services__item__tags">
						{frontendTags.map((tag, i) => (
							<div className="home__services__item__tag" key={i}>
								{tag}
							</div>
						))}
					</div>
				</div>

				<div className="home__services__item">
					<div className="home__services__item__description">
						<h3>Backend development</h3>

						<p>
							We offer custom web and mobile application back-end development services for various profiles and industry focuses. We use an industry-standard, versatile and highly efficient technical platform, perfectly suitable for virtually any digital product development, scaling and painless maintenance.
						</p>
					</div>

					<div className="home__services__item__tags">
						{backendTags.map((tag, i) => (
							<div className="home__services__item__tag" key={i}>
								{tag}
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
}