import {useState} from 'react';

import HireForm from './../components/HomePage/HireForm';

export default function HireFormContainer() {
	const [data, setData] = useState({
		name: '',
		email: '',
		question: '',
	});

	function handleFormSubmit(e) {
		e.preventDefault();
	}

	function handleInputChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	}

	return (
		<HireForm
			data={data}
			onSubmit={handleFormSubmit}
			onInputChange={handleInputChange}
		/>
	)
}