import mrlens from './../../../assets/images/works/mrlens.png';
import shootdotedit from './../../../assets/images/works/shootdotedit.png';
import openspace from './../../../assets/images/works/openspace.png';
import mbrico from './../../../assets/images/works/mbrico.png';

const works = [
	{
		title: 'Mrlens',
		img: mrlens,
		description: 'MrLens, Switzerland’s largest contact lens shop, will deliver your prescription glasses, sunglasses, contact lenses and care products directly to your home or office. Fast, reliable and with the best terms.',
		type: 'Frontend, backend',
		technologies: ['PHP', 'JavaScript'],
		backgroundColor: '#81CEF9'
	},
	{
		title: 'Shoot Dot Edit',
		img: shootdotedit,
		description: 'Fast, personalized, and consistent wedding photo editing services for pro wedding photographers',
		type: 'Frontend',
		technologies: ['React', 'JavaScript'],
		backgroundColor: '#C87BCF'
	},
	{
		title: 'Santa Clara Valley Open Space Authority',
		img: openspace,
		description: 'Fast, personalized, and consistent wedding photo editing services for pro wedding photographers',
		type: 'Frontend',
		technologies: ['React', 'JavaScript'],
		backgroundColor: '#487B5C'
	},
	{
		title: 'Mbrico',
		img: mbrico,
		description: 'Mbrico manufactures & provides a premium porcelain tile system with a better design, easier installation, lower maintenance, and more color options.',
		type: 'Frontend',
		technologies: ['React', 'JavaScript'],
		backgroundColor: '#282839'
	}
];

export default function Works() {
	return (
		<section className="home__works" id="works">
			<h2 className="text--center">Featured works</h2>

			{works.map((project, i) => (
				<div className="project" key={i} style={{backgroundColor: project.backgroundColor}}>
					<div className="container-h container-v">
						<div className="project__inner">
							<div>
								<h4 className="project__title">{project.title}</h4>

								<p>{project.description}</p>

								{project.type && (
									<p>{project.type}</p>
								)}

								{project.technologies && (
									<div className="project__technology">
										<div className="project__technology__title text--lead">Technology</div>

										{project.technologies.map((tech, i) => (
											<p key={i}>{tech}</p>
										))}
									</div>
								)}
							</div>

							<img src={project.img} alt={project.title}/>
						</div>
					</div>
				</div>
			))}
		</section>
	);
}