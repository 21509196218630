import HireFormContainer from './../../containers/HireFormContainer';

export default function Hire() {
    return (
        <section className="home__hire" id="hire-us">
            <div className="container-h container-v">
                <h2 className="text--center">Hire us</h2>

                <HireFormContainer/>
            </div>
        </section>
    );
}